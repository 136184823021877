module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-5GC9PVJ","includeInDevelopment":true,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}},"routeChangeEventName":"gatsby-route-change"},
    },{
      plugin: require('../node_modules/gatsby-plugin-anchor-links/gatsby-browser.js'),
      options: {"plugins":[],"offset":-100},
    },{
      plugin: require('../node_modules/@prismicio/gatsby-source-prismic-graphql/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"workhub-website-gatsby","defaultLang":"en-us","pages":[{"type":"Partner","match":"resources/partners/:uid","path":"/partner-preview","component":"/opt/build/repo/src/templates/partners/partner.js"},{"type":"Course","match":"/features/training/:uid","path":"/course-preview","component":"/opt/build/repo/src/templates/features/training/course.js"},{"type":"Blog","match":"/resources/blog/:uid","path":"/resources/blog","component":"/opt/build/repo/src/templates/resources/blog/blog.js"},{"type":"Category","match":"/resources/blog/categories/:uid","path":"/resources/blog/categories","component":"/opt/build/repo/src/templates/resources/blog/category/category.js"}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"minimal-ui","icon":"src/images/favicon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"17c4293129692e197de0230d5c1e11b0"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
